<template>
  <ServiceRadioField :choices="serviceSelections" v-on="$listeners" />
</template>

<script>
import {
  windowFramesPlasticBE,
  windowFramesWoodBE,
  windowFramesAluminiumBE,
} from 'chimera/windowFrames/service'
import { Selectable } from 'chimera/all/components/models/Selectable'
import ServiceRadioField from 'chimera/all/components/form/fields/service/ServiceRadioField'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      serviceSelections: [
        new Selectable(
          'Cadres en PVC',
          'Cadres en PVC',
          windowFramesPlasticBE.id,
        ),
        new Selectable(
          'Cadres en bois',
          'Cadres en bois',
          windowFramesWoodBE.id,
        ),
        new Selectable(
          'Cadres en aluminium',
          'Cadres en aluminium',
          windowFramesAluminiumBE.id,
        ),
      ],
    }
  },
}
</script>
